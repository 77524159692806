<template>
  <div>
    <div class="px-5 py-2">

      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage">
      </loading>

      <div class="row justify-content-md-center">
        <div class="col-12">
          <filter-side-bar>
            <template slot="ContentSideBar">
              <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
                <h5 class="mb-0">Filtrar por:</h5>

                <!-- filtros... -->

                <div class="py-1 text-center">
                  <div class="md-form mb-0">
                    <div class="row">
                      <div class="col-6">
                        <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                        </button>
                      </div>
                      <div class="col-6">
                        <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                          {{ $t('filter.reset') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </filter-side-bar>
        </div>
      </div>
    </div>

    <div class="abaixo px-5 py-2">
      <div class="row justify-content-md-center">
        <!-- Bloco com os Filtros -->
        <div class="col-12 p-2 mb-3">
<!--          <filter-container :activeFilterList="activeFilterList" />-->
        </div>

        <div class="col-12 col-sm-12 p-2">
          <div class="row">
            <div class="col-12 p-0">
              <div class="card p-0">
                <div class="card-body p-0">
                  <!-- Conteúdo -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import FilterSideBar from '@/components/FilterSideBar.vue'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
// import DashboardService from '@/services/DashboardService'
// import moment from 'moment'

export default {
  name: 'DashboardSlots',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.carousel') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      multiSort: true,
      activeFilterList: {
      },
      filter: {}
    }
  },
  components: {
    Loading,
    FilterSideBar
    // Vuetable,
    // VuetablePagination
  },
  // Apply filters to local filter
  beforeMount () {
  },
  created () {
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  methods: {
    showModal () {
      // this.$modal.show(TaskLog, {}, {
      //   draggable: false,
      //   adaptive: true,
      //   scrollable: true,
      //   clickToClose: false,
      //   width: '80%',
      //   height: 'auto'
      // })
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id: object.id } }).href
    },
    handleLoadError (response) {
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    buildFilters () {
      this.filter.users = (this.filters.filUsers) ? this.filters.filUsers.map(x => (x.id)) : []
      this.filter.employees = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
      this.filter.id_status = null
    },
    applyFilter () {
      this.employeeList = []
      this.userList = []
      this.customerList = []

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuickSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuickSidebar()

      this.filters.filCliente = []
      this.filters.filEmployees = []
      this.filters.filUsers = []
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .btn-padding {
    padding: 0.55rem 0.30rem
  }

</style>

<style>
.badge-draft {
  background-color: #ababab !important;
  color: white !important;
}

.badge-draft:hover {
  background-color: #ababab !important;
  color: white !important;
}

.badge-waiting {
  background-color: #f1ba26 !important;
  color: white !important;
}

.badge-waiting:hover {
  background-color: #f1ba26 !important;
  color: white !important;
}

.badge-confirmed {
  background-color: #5ec65f !important;
  color: white !important;
}

.badge-confirmed:hover {
  background-color: #5ec65f !important;
  color: white !important;
}

.badge-not-confirmed {
  background-color: #F3614E !important;
  color: white !important;
}

.badge-not-confirmed:hover {
  background-color: #F3614E !important;
  color: white !important;
}

.badge-cancel {
  background-color: #F3614E !important;
  color: white !important;
}

.badge-cancel:hover {
  background-color: #F3614E !important;
  color: white !important;
}

.abaixo {
  background-color: white;
}
</style>
